<template>
  <div>
    <iframe :src='frame' width='100%' height='1200px' frameborder='0'></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import { formatPrice, sumdate } from '@/plugins/filters'
import vatDataPrint from '@/@fake-db/data/vatDataPrint.json'
import 'jspdf-autotable'
import shop from '@/api/systemSetting/shop'

export default {
  setup() {
    const { route } = useRouter()
    const vatList = ref(vatDataPrint.data)
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(14)
    const fontBody = ref(13)
    const lastTable = ref(0)
    const shop_print_type = ref(0)
    const frame = ref('')
    const shopData = ref({})
    const receiptData = ref({})

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    const printsOrder = () => {
      prints
        .printsOrder({
          order_id_pri: route.value.query.id,
          lang: i18n.locale,
        })
        .then(res => {
          dataTableList.value = res.orderdetail
          receiptData.value = res
          savePDF(res)
        })
    }
    shop.shopGet().then(res => {
      shop_print_type.value = route.value.query.shop_print_type
      shopData.value = res
      printsOrder()
    })
    const generateData = () => {
      const result = []
      let drugTotal = 0
      let drugDiscount = 0
      let priceUnit = 0
      let count = 0
      let name = ''

      dataTableList.value.forEach((item, index) => {
        // drug
        if (route.value.query.showDrugStatus == 'true' && route.value.query.showCourseStatus == 'false') {
          if (item.orderdetail_type_id == '2') {
            count += 1
            drugTotal += +item.orderdetail_total.replaceAll(',', '') 
            drugDiscount += +(item.orderdetail_amount).replaceAll(',', '') * +(item.orderdetail_discount).replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '') * +(item.orderdetail_amount).replaceAll(',', '')
            name = i18n.t('drug_and_service')
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              formatPrice(drugTotal),
              formatPrice(drugDiscount),
              formatPrice(drugTotal),
            ])
          }
        }

        // check and course
        if (route.value.query.showCourseStatus == 'true' && route.value.query.showDrugStatus == 'false') {
          if (item.orderdetail_type_id == '1' || item.orderdetail_type_id == '3') {
            count += 1
            drugTotal += +item.orderdetail_total.replaceAll(',', '') 
            drugDiscount += +(item.orderdetail_amount).replaceAll(',', '') * +(item.orderdetail_discount).replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '') * +(item.orderdetail_amount).replaceAll(',', '')
            name = i18n.t('service_and_treatment_fees')
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              formatPrice(drugTotal),
              formatPrice(drugDiscount),
              formatPrice(drugTotal),
            ])
          }
        }

        // all check
        if (route.value.query.showCourseStatus == 'true' && route.value.query.showDrugStatus == 'true') {
          if (item.orderdetail_type_id == '1' || item.orderdetail_type_id == '2' || item.orderdetail_type_id == '3') {
            count += 1
            drugTotal += +item.orderdetail_total.replaceAll(',', '') 
            drugDiscount += +(item.orderdetail_amount).replaceAll(',', '') * +(item.orderdetail_discount).replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '') * +(item.orderdetail_amount).replaceAll(',', '')
            name = i18n.t('drug_and_treatment_fees')
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              formatPrice(drugTotal),
              formatPrice(drugDiscount),
              formatPrice(drugTotal),
            ])
          }
        }

        // bold
        if (route.value.query.showDrugStatus == 'false' && route.value.query.showCourseStatus == 'false') {
          result.push([
            `${item.orderdetail_name} (${item.orderdetail_id})`,
            `${item.orderdetail_amount}`,
            item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
            item.orderdetail_price,
            formatPrice(item.orderdetail_discount * item.orderdetail_amount),
            item.orderdetail_total,
          ])
        }
      })
      result.map((item, index) => (item[0] = `${index + 1}) ${item[0]}`))

      return result
    }
    const headers = [
      `${i18n.t('list')}`,
      i18n.t('amount'),
      i18n.t('unit'),
      i18n.t('price_unit'),
      i18n.t('discount'),
      i18n.t('total_amount'),
    ]

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
      })
      doc.setProperties({
        title: `DEPOSIT RECEIPT ${data.order_id} - ${data.order_create}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-bold.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-bold.ttf', 'TH-Niramit', 'bold')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      doc.autoTable({
        theme: 'plain',
        styles: {
          font: 'TH-Niramit',
          fontStyle: 'bold',
          textColor: '#000',
          fontSize: fontBody.value,
          halign: 'left',
        },
        showFoot: 'lastPage',
        headStyles: {
          textColor: '#1E88E5',
          fontStyle: 'bold',
          fontSize: fontTitle.value,
          halign: 'center',
        },
        columnStyles: {
          1: { halign: 'center', fillColor: '#F5F5F5', cellWidth: 16 },
          2: { halign: 'center', cellWidth: 18 },
          3: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 22 },
          4: { halign: 'right', cellWidth: 22 },
          5: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 22 },
        },
        startY: 85,
        margin: {
          top: 85,
          left: 5,
          right: 5,
          bottom: 90,
        },
        head: [headers],
        body: generateData(),

        didDrawPage(datax) {
          const ImageToLoad = new Image()
          ImageToLoad.src = shopData.value.shop_image
          doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 7, 6, 28, 26)

          // Start Address Shop block
          if (shop_print_type.value == '1') {
            doc.setFontSize(fontTitle.value)
            doc.setFillColor('#000')
            doc.text(`${data.shop_name} ${data.shop_nature}`, 40, 10)
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontBody.value)
            doc.text(`${data.shop_address}  ${i18n.t('reportpsychotropic2_name_7')} ${data.shop_district}`, 40, 15)
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${data.shop_amphoe} ${i18n.t('reportpsychotropic2_name_9')} ${
                data.shop_province
              } ${i18n.t('zip_code')} ${data.shop_zipcode}`,
              40,
              20,
            )
            doc.text(`${i18n.t('tel')} ${data.shop_tel} ${i18n.t('email')} ${data.shop_email}`, 40, 25)
            doc.text(`${i18n.t('numberLicenses')} ${data.shop_license} ${i18n.t('tax_id')} ${data.shop_tax}`, 40, 30)
            doc.setLineWidth(0.8)
            doc.setDrawColor('#1E88E5')
            doc.line(61, 34, 205, 34)
            doc.setDrawColor('#000')
            doc.line(4, 34, 60, 34)
          } else {
            doc.setFontSize(fontTitle.value)
            doc.setFillColor('#000')
            doc.text(`${shopData.value.shop_company_name}`, 40, 10)
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontBody.value)
            doc.text(
              `${shopData.value.shop_company_address}  ${i18n.t('reportpsychotropic2_name_7')} ${
                shopData.value.shop_company_district
              }`,
              40,
              15,
            )
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${shopData.value.shop_company_amphoe} ${i18n.t(
                'reportpsychotropic2_name_9',
              )} ${shopData.value.shop_company_province} ${i18n.t('zip_code')} ${shopData.value.shop_company_zipcode}`,
              40,
              20,
            )
            doc.text(
              `${i18n.t('tel')} ${shopData.value.shop_company_tel} ${i18n.t('email')} ${
                shopData.value.shop_company_email
              }`,
              40,
              25,
            )
            doc.text(`${i18n.t('numberLicenses')} ${data.shop_license} ${i18n.t('tax_id')} ${data.shop_tax}`, 40, 30)
            doc.setLineWidth(0.8)
            doc.setDrawColor('#1E88E5')
            doc.line(61, 34, 205, 34)
            doc.setDrawColor('#000')
            doc.line(4, 34, 60, 34)
          }
          doc.setLineWidth(0.1)
          doc.setDrawColor('#000')
          doc.line(4, 85, 205, 85)
          doc.line(4, 94, 205, 94)

          // End Address Shop block

          // start Address customer

          if (route.value.query.showCompany == 'false') {
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontTitle.value)
            doc.text(
              `${i18n.t('contacts')} ${data.customer_name} ${i18n.t('customer_code')} ${data.customer_id}`,
              5,
              40,
            )
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontBody.value)
            doc.text(`${i18n.t('tax_id')} ${data.customer_idcard}`, 5, 45)
            doc.text(
              `${data.customer_address || ''}${data.customer_address ? ' ' : ''}${i18n.t(
                'reportpsychotropic2_name_7',
              )} ${data.customer_district}`,
              5,
              50,
            )
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${data.customer_amphoe} ${i18n.t(
                'reportpsychotropic2_name_9',
              )} ${data.customer_province} ${i18n.t('zip_code')} ${data.customer_zipcode}`,
              5,
              55,
            )
            doc.text(`${i18n.t('tel')} ${data.customer_tel}`, 5, 60)
            doc.text(
              `${i18n.t('receive_points')} ${data.order_point} ${i18n.t('point')}  ${i18n.t(
                'reward_points',
              )} ${formatPrice(data.customer_point || 0)} ${i18n.t('point')}`,
              5,
              65,
            )
          } else {
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontTitle.value)
            doc.text(`${data.company_name}`, 5, 40)
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontBody.value)
            doc.text(
              `${i18n.t('contacts')} ${data.customer_name} ${i18n.t('customer_code')} ${data.customer_id}`,
              5,
              45,
            )
            doc.text(`${i18n.t('tax_id')} ${data.company_tax}`, 5, 50)
            doc.text(
              `${data.company_address || ''}${data.company_address ? ' ' : ''}${i18n.t('reportpsychotropic2_name_7')} ${
                data.company_district
              }`,
              5,
              55,
            )
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${data.company_amphoe} ${i18n.t('reportpsychotropic2_name_9')} ${
                data.company_province
              } ${i18n.t('zip_code')} ${data.company_zipcode}`,
              5,
              60,
            )
            doc.text(`${i18n.t('tel')} ${data.company_tel}`, 5, 65)
            doc.text(
              `${i18n.t('receive_points')} ${data.order_pointsave} ${i18n.t('point')}  ${i18n.t(
                'reward_points',
              )} ${formatPrice(data.customer_point || 0)} ${i18n.t('point')}`,
              5,
              70,
            )
          }
          doc.setFont('TH-Niramit', 'bold')
          doc.setFontSize(fontTitle.value)
          doc.setTextColor('#1565C0')
          doc.text(`${i18n.t('deposit_receipt')}`, 204, 45, null, null, 'right')
          doc.text('[DEPOSIT RECEIPT]', 204, 50, null, null, 'right')
          if (data.order_status_id == '2') {
            doc.setFontSize(20)
            doc.setTextColor('#FF1744')
            doc.text(`(${i18n.t('cancel')})`, 204, 55, null, null, 'right')
          }
          doc.setDrawColor(0)
          doc.setFillColor('#1E88E5')
          doc.rect(106, 67, 99, 16, 'F')

          doc.setTextColor('#fff')
          doc.setFont('TH-Niramit', 'bold')
          doc.setFontSize(fontTitle.value)
          doc.text(`${i18n.t('a_number')}`, 130, 73, null, null, 'left')
          doc.text(`${i18n.t('date')}`, 170, 73, null, null, 'left')
          doc.text(`${data.order_id}`, 130, 80, null, null, 'left')
          doc.text(`${sumdate(data.order_create)}`, 170, 80, null, null, 'left')
          doc.setFont('TH-Niramit', 'bold')
          doc.setTextColor('#1E88E5')
          doc.setFontSize(fontTitle.value)
          const str = `${i18n.t('page')} ${doc.internal.getNumberOfPages()}`
          doc.setFontSize(10)
          doc.text(str, 197, 10)
          lastTable.value = datax.cursor.y
          doc.setFontSize(fontBody.value)
          doc.setTextColor('#000')
          doc.text(
            `LINE ID : ${shopData.value.shop_line || ''} IG : ${shopData.value.shop_instagram || ''} FB : ${
              shopData.value.shop_facebook || ''
            }`,
            5,
            288,
          )
          doc.setLineWidth(0.8)
          doc.setDrawColor('#1E88E5')
          doc.line(4, 290, 60, 290)
          doc.setDrawColor('#000')
          doc.line(61, 290, 205, 290)
        },
      })
      doc.setDrawColor(0)
      doc.setFillColor('#F5F5F5')
      doc.rect(7, lastTable.value + 3, 110, 10, 'F')
      doc.setFillColor('#1E88E5')
      doc.rect(120, lastTable.value + 44, 85, 10, 'F')
      doc.setFont('TH-Niramit', 'bold')
      doc.setFontSize(fontTitle.value)
      doc.setTextColor('#000')
      doc.text(data.net_amount_thai, 61, lastTable.value + 10, null, null, 'center')

      doc.setFontSize(13)
      doc.text(`${i18n.t('paymentMethod')} : ${data.shop_bank_name}`, 7, lastTable.value + 20)
      doc.text(`${i18n.t('deposit')} : ${data.order_deposit_amount} ${i18n.t('bath')}`, 7, lastTable.value + 25)
      doc.text(
        `${i18n.t('balance')} : ${formatPrice(
          +data.order_total.replaceAll(',', '') - +data.order_deposit_amount.replaceAll(',', ''),
        )} ${i18n.t('bath')}`,
        7,
        lastTable.value + 30,
      )

      doc.text(`${i18n.t('note')} : ${data.order_comment}`, 7, lastTable.value + 35)
      doc.text(`${i18n.t('other')} :`, 7, lastTable.value + 50)
      doc.text(`${shopData.value.shop_comment ? shopData.value.shop_comment : ''}`, 7, lastTable.value + 60)
      const arr = [
        { name: `${(data.order_tax_id) == '2' ? i18n.t('valueBeforeVat') : i18n.t('valueBeforeVat1')}`,  value: data.order_price },
        { name: i18n.t('discount'), value: data.order_save },
        { name: i18n.t('discount_points'), value: data.order_pointsave },

        {
          name:
            route.value.query.lang == '2'
              ? vatList.value[+data.order_tax_id - 1].en
              : vatList.value[+data.order_tax_id - 1].th,
          value: data.order_tax,
        },
        { name: `${(data.order_tax_id) == '2' ? i18n.t('valueBeforeVat1') : i18n.t('valueBeforeVat')}`, value: data.order_total },
        { name: i18n.t('netTotalPrice'), value: data.net_amount },
      ]

      for (let i = 0; i < arr.length; i++) {
        const x = 8
        if (arr[i].name == i18n.t('netTotalPrice')) {
          doc.setTextColor('#fff')
        }
        doc.text(arr[i].name, 160, lastTable.value + 10 + x * i, null, null, 'right')
        doc.text(arr[i].value, 201, lastTable.value + 10 + x * i, null, null, 'right')
      }
      doc.addImage(data.qrcode_image || '', 'JPEG', 93, lastTable.value + 15, 25, 25)
      doc.setFontSize(fontTitle.value)
      doc.setTextColor('#000')
      doc.text(i18n.t('userPrintDoc3'), 120, lastTable.value + 65, null, null, 'center')
      doc.text(i18n.t('userPrintDoc2'), 180, lastTable.value + 65, null, null, 'center')

      doc.setLineWidth(0.2)
      doc.setDrawColor('#000')
      doc.line(100, lastTable.value + 75, 139, lastTable.value + 75)
      doc.line(160, lastTable.value + 75, 200, lastTable.value + 75)
      doc.text(sumdate(data.order_create), 120, lastTable.value + 82, null, null, 'center')
      doc.text(sumdate(data.order_create), 180, lastTable.value + 82, null, null, 'center')

      frame.value = doc.output('datauristring')
    }
    const go = () => {
      window.open(frame.value, '_blank')
    }

    return { savePDF, frame, go }
  },
}
</script>
